import Image from 'next/image';
import { ButtonGroup } from '@nextui-org/react';
import { useEffect, useMemo, useState } from 'react';
import Button from '@atoms/new/button';
import { PLATFORMS } from '@components/sessions/platforms';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@atoms/new/dropdown';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

interface PlatformsTabsProps {
  platform?: string;
  selectPlatform: (platform: string) => void;
}

export const PlatformsTabs = ({
  platform = 'zoom',
  selectPlatform,
}: PlatformsTabsProps) => {
  const [selectedOption, setSelectedOption] = useState(new Set([platform]));

  const selectedPlatform = useMemo(
    () => Array.from(selectedOption).join(', ').replaceAll('_', ' '),
    [selectedOption]
  );

  useEffect(() => {
    selectPlatform(selectedPlatform);
  }, [selectedPlatform]);

  return (
    <ButtonGroup className="bg-grey-50 rounded-md p-0.5 gap-0.5">
      <Button
        kind="secondary"
        style={{ borderRadius: '6px' }}
        className={`px-3 ${selectedPlatform === PLATFORMS.zoom.slug ? 'bg-grey-200' : 'bg-grey-50'}`}
        onClick={() => setSelectedOption(new Set([PLATFORMS.zoom.slug]))}
        startContent={
          <Image
            alt={`${PLATFORMS.zoom.title}-platform-logo`}
            src={PLATFORMS.zoom.logo}
            width={20}
            height={20}
          />
        }
      >
        {PLATFORMS.zoom.title}
      </Button>
      <Button
        kind="secondary"
        style={{ borderRadius: '6px' }}
        className={`px-3 ${selectedPlatform === PLATFORMS.teams.slug ? 'bg-grey-200' : 'bg-grey-50'}`}
        onClick={() => setSelectedOption(new Set([PLATFORMS.teams.slug]))}
        startContent={
          <Image
            alt={`${PLATFORMS.teams.title}-platform-logo`}
            src={PLATFORMS.teams.logo}
            width={20}
            height={20}
          />
        }
      >
        {PLATFORMS.teams.title}
      </Button>
      <Button
        kind="secondary"
        style={{ borderRadius: '6px' }}
        className={`px-3 rounded-md ${selectedPlatform === PLATFORMS.youtube.slug ? 'bg-grey-200' : 'bg-grey-50'}`}
        onClick={() => setSelectedOption(new Set([PLATFORMS.youtube.slug]))}
        startContent={
          <Image
            alt={`${PLATFORMS.youtube.title}-platform-logo`}
            src={PLATFORMS.youtube.logo}
            width={20}
            height={20}
          />
        }
      >
        {PLATFORMS.youtube.title}
      </Button>
      <Dropdown placement="bottom-end">
        <DropdownTrigger>
          <Button
            kind="secondary"
            style={{ borderRadius: '6px' }}
            className="px-3 bg-grey-50"
            endContent={<KeyboardArrowDownRoundedIcon fontSize="small" />}
          >
            More
          </Button>
        </DropdownTrigger>
        <DropdownMenu
          disallowEmptySelection
          aria-label="rest-of-the-platforms"
          selectedKeys={selectedOption}
          selectionMode="single"
          // @ts-expect-error - DropdownMenu expects a function
          onSelectionChange={setSelectedOption}
        >
          {Object.values(PLATFORMS)
            .filter((platform) =>
              [
                'meet',
                'twitch',
                'ipa',
                'streamyard',
                'linkedin',
                'airmeet',
                'restream',
                'kick',
              ].includes(platform.slug)
            )
            .sort((a, b) => a.order - b.order)
            .map((platform) => (
              <DropdownItem key={platform.slug} textValue={platform.title}>
                <Platform title={platform.title} logo={platform.logo} />
              </DropdownItem>
            ))}
        </DropdownMenu>
      </Dropdown>
    </ButtonGroup>
  );
};

interface PlatformProps {
  title: string;
  logo: string;
}

export const Platform = ({ logo, title }: PlatformProps) => {
  return (
    <div className="flex items-center gap-2">
      <Image alt={`${title}-platform-logo`} src={logo} width={20} height={20} />
      <p>{title}</p>
    </div>
  );
};
